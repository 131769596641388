<template>
    <div class="contact_edit">
        <van-form @submit="save">
            <van-field
                v-model="contact_name"
                name="contact_name"
                label="联系人姓名"
                placeholder="联系人姓名"
                :required='true'
                :rules="[{ required: true, message: '请填写联系人姓名' }]"
            />
            <van-field
                v-model="contact_tellphone"
                type="contact_tellphone"
                name="联系电话"
                label="联系电话"
                placeholder="联系电话"
                :required='true'
                :rules="[{ required: true, message: '请填写联系电话' }]"
            />
            <van-field
                readonly
                clickable
                name="picker"
                :value="valueName"
                label="联系关系"
                :required='true'
                :rules="[{ required: true, message: '请选择联系关系' }]"
                placeholder="点击选择联系关系"
                @click="showPicker = true"
            />
            <div>
                <van-button native-type="submit" type="primary" block>关联家属</van-button>
                <!-- <van-button native-type="submit" type="warning" block>取消关联</van-button> -->
            </div>
        </van-form>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                value-key="label"
                @confirm="onConfirm"
                @cancel="showPicker = false"
            />
        </van-popup>
        <loadings :loading='searchLoading' />
    </div>
</template>

<script>
    import { Form,Field,Popover,Picker } from 'vant'
    import Loadings from '@/components/loading'
    export default {
        name:'edit',
        data() {
            return {
                contact_name:'',
                contact_tellphone:'',
                value:'',
                valueName:'',
                showPicker: false,
                columns:[
                    { label: '无', value: 0 },
                    { label: '爸爸', value: 1 },
                    { label: '妈妈', value: 2 },
                    { label: '姐姐', value: 3 },
                    { label: '弟弟', value: 4 },
                    { label: '哥哥', value: 5 },
                    { label: '朋友', value: 6 },
                    { label: '爷爷', value: 7 },
                    { label: '奶奶', value: 8 },
                    { label: '外公', value: 20 },
                    { label: '外婆', value: 30 },
                    { label: '本人', value: 9 },
                    { label: '保姆', value: 10 },
                    { label: '其他', value: 11},
                ],
                id:'',
                searchLoading: false,

            }
        },
        components: {
            [Form.name]:Form,
            [Field.name]:Field,
            [Picker.name]:Picker,
            [Popover.name]:Popover,
            loadings:Loadings,

        },
        created () {
            if(this.$route.query.id){
                this.id = this.$route.query.id
                this.searchLoading = true
                this.getDetail() 
            }
        },
        methods: {
            getDetail(){
                this.$api.profile_contact_detail({id:this.id})
                .then(res=>{
                    this.contact_name = res.data.contact_name
                    this.contact_tellphone = res.data.contact_tellphone
                    this.columns.forEach(item=>{
                        if(item.value == res.data.contact_type){
                            this.value = item
                            this.valueName = item.label
                        }
                    })
                    this.searchLoading = false
                })
            },
            save(){
                if(this.id){
                    this.$api.profile_update_contact({id:this.id,contact_type:this.value.value,contact_name:this.contact_name,contact_tellphone:this.contact_tellphone})
                    .then(res=>{
                        this.$toast.success('操作成功~')
                        this.$router.go(-1)
                    })
                    .catch(err=>{
                        this.$toast.fail('操作失败~')
                    })
                }else{
                    this.$api.profile_add_contact({contact_type:this.value.value,contact_name:this.contact_name,contact_tellphone:this.contact_tellphone})
                    .then(res=>{
                        this.$toast.success('操作成功~')
                        this.$router.go(-1)
                    })
                    .catch(err=>{
                        this.$toast.fail('操作失败~')
                    })
                }
            },
            onConfirm(e) {
                this.value = e
                this.valueName = e.label
                this.showPicker = false
            }
        },
    }
</script>

<style lang="less" scoped>
    .contact_edit{
        width: 95%;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 8px;
        background: #fff;
        overflow: hidden;
    }
    .contact-edit-btn{
        position: fixed;
        bottom: 0;
        width: 100%;
    }
</style>